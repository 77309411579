import styled from "styled-components";

export const BlogThumb = styled.figure `
    height: 100%;
    width: 100%;
    position: relative;
    a {
        display: block;
    }
    img{
        border-radius: 5px;
    }
`;
